export type TrackingGroup = { [key: string]: string };

export const SRPCardsDataTracking: { [key: string]: TrackingGroup } = {
  brandBanner: {
    brandBanner: 'agent_branding_top',
    leftSlot: 'agent_branding_logo',
    rightMaskedImage: 'agent_branding_negotiator',
    rightTitle: 'agent_branding_negotiator_name',
    rightSubtitle: 'agent_branding_agent_name',
  },
  gallery: {
    gallery: 'srp_gallery',
    galleryImageContainer: 'srp_gallery_hero',
    imageGrid: 'srp_gallery_grid',
    galleryButtonPrevious: 'srp_gallery_button_left',
    galleryButtonNext: 'srp_gallery_button_right',
    bottomLeftComponent: 'srp_offers_badge',
    bottomRightComponent: 'srp_branded_agent_logo',
    centerSlot: 'srp_view_all_photos',
  },
  cardContent: {
    subtitle: 'srp_address',
    tagline: 'srp_tagline',
    price: 'srp_price',
    advantage_badge: 'srp_advantage_badge',
    agentName: 'agent_name',
    viewing: 'open_viewing',
    metaLabel: 'srp_meta',
    ber: 'srp_ber',
    sticker: 'srp_sticker',
    srp_save_ad: 'srp_save_ad',
  },
  subunit: {
    subunit: 'srp_units',
    remainingUnits: 'srp_units_more',
  },
};
