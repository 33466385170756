import React, { FC } from 'react';

import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon } from '@dsch/icons';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const css = require('styled-components').css;

export type PopTartProps = {
  title?: string;
  message?: string;
  type?: 'SUCCESS' | 'WARNING' | 'ERROR';
  icon?: string;
};

type StyledPopTartProps = {
  type: string;
};

const PopTartType = (props: StyledPopTartProps): string => {
  switch (props.type) {
    case 'SUCCESS':
      return 'rgba(0, 166, 82, 0.9)';
    case 'WARNING':
      return 'rgba(245, 133, 31, 0.9)';
    case 'ERROR':
      return 'rgba(213, 0, 0, 0.9)';
    default:
      return '';
  }
};

const StyledPopTart = styled.div`
  color: white;
  width: 100vw;
  border-radius: 0;
  min-height: 108px;
  max-height: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: StyledPopTartProps) => PopTartType(props)};
  position: relative;

  @media only screen and (min-width: 480px) {
    width: 248px;
    border-radius: 6px;
  }
`;

const StyledPopTartContent = styled.div`
  margin: 0;
  padding: 16px;
  width: 100%;
`;

const StyledPopTartTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledMessage = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  max-height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 auto;
  margin: 16px 0 8px;

  ${({ isTitleDisplayed }: { isTitleDisplayed: boolean }) =>
    isTitleDisplayed && 'margin-top: 10px;'};

  &:only-child {
    margin-top: 0;
  }
`;

const styledPopTartIcons = css`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  ${styledPopTartIcons};
`;

const StyledWarningIcon = styled(WarningIcon)`
  ${styledPopTartIcons};
`;

const StyledErrorIcon = styled(ErrorIcon)`
  ${styledPopTartIcons};
`;

const generateDefaultMessage = ({ type, message, title }: PopTartProps) => {
  switch (type) {
    case 'WARNING':
      return {
        updatedIcon: 'WARNING',
        updatedTitle: title || '',
        updatedMessage: message || 'Sorry, something went wrong.',
      };
    case 'ERROR':
      return {
        updatedIcon: 'ERROR',
        updatedTitle: title || '',
        updatedMessage: message || 'Sorry, something went wrong.',
      };
    default:
      return {
        updatedIcon: 'SUCCESS',
        updatedTitle: title || '',
        updatedMessage: message || '',
      };
  }
};

const Icons: { [key: string]: JSX.Element } = {
  SUCCESS: <StyledSuccessIcon />,
  WARNING: <StyledWarningIcon />,
  ERROR: <StyledErrorIcon />,
};

const PopTartNotification: FC<PopTartProps> = (props: PopTartProps) => {
  const { type = 'SUCCESS' } = props;

  const { updatedTitle, updatedMessage, updatedIcon } = generateDefaultMessage({
    ...props,
  });

  const messageGreaterThanLimit = updatedMessage && updatedMessage.length > 70;
  const shouldDisplayIcon = !(updatedTitle || messageGreaterThanLimit);
  const shouldDisplayTitle = !(messageGreaterThanLimit && updatedTitle);
  const isTitleDisplayed = Boolean(shouldDisplayTitle && !shouldDisplayIcon);

  return (
    <StyledPopTart data-testid="PopTart" type={type}>
      <StyledPopTartContent>
        <CloseIconContainer>
          <CloseIcon />
        </CloseIconContainer>
        {shouldDisplayIcon && Icons[updatedIcon]}
        {shouldDisplayTitle && (
          <StyledPopTartTitle>{updatedTitle}</StyledPopTartTitle>
        )}
        {updatedMessage && (
          <StyledMessage isTitleDisplayed={isTitleDisplayed}>
            {updatedMessage}
          </StyledMessage>
        )}
      </StyledPopTartContent>
    </StyledPopTart>
  );
};

const show = (props?: PopTartProps) => {
  toast(<PopTartNotification {...props} />);
};

export const PopTart = {
  show,
};
