// This file should be moved to the toolkit package or somewhere that can be shared across all apps
//we should be able to use generic types, and the consumer should be able to pass in the custom components
import {
  Card,
  CardContainer,
  CustomCardGallery,
  CustomCardBanner,
  CardContainerCornerRadius,
  CustomCardContent,
  determineBottomSlotContent,
  CardBannerUIType,
  CardContentUIType,
  CardLayout,
  SrpCardSubUnit,
  RemainingSubunitsCard,
  CardContainerBorder,
} from '@dist-property-frontend/toolkit';
import React, { FunctionComponent } from 'react';
import { CardGalleryPropsType } from '../../SearchPage/services/srpCard.types';
import { SrpCardSubUnitPropsType } from '../../SearchPage/services/propertyUnits.types';

/**
 *
 * Factory for different card UIs
 *
 */

type CardFactoryProps = {
  id: number;
  cardBannerProps?: CardBannerUIType;
  cardContentProps?: CardContentUIType;
  cardGalleryProps?: CardGalleryPropsType;
  cardLayoutProps?: CardLayout;
  cardSubUnitsProps?: SrpCardSubUnitPropsType[];
  cardSubunits?: SrpCardSubUnitPropsType[];
  remainingSubunits?: number;
};

const CreateSRPBaseCard: FunctionComponent<CardFactoryProps> = (props: any) => {
  const { layout, cardGallery, cardBanner, cardContent, hasSubunits } = props;

  // Prepare the card content layout from cardContentLayout props
  const cardContentLayoutData = {
    bottomSlotContent: determineBottomSlotContent(
      cardContent?.advantageLabel,
      cardContent?.agentBranch,
    ),
    hasBottomSlot: !cardContent?.hideBottomSlot,
    cardContentPaddingX: '16px',
    cardContentPaddingY: '16px',
  };

  const cardContainerProps = {
    cornerRadius: hasSubunits
      ? CardContainerCornerRadius.TOP
      : CardContainerCornerRadius.ON,
    cornerRadiusMobile: CardContainerCornerRadius.OFF,
    border: hasSubunits ? CardContainerBorder.OFF : undefined,
    borderMobile: hasSubunits ? CardContainerBorder.OFF : undefined,
    backgroundColor: 'white',
    mobileFullWidth: !hasSubunits,
  };

  return (
    <CardContainer {...cardContainerProps}>
      <Card
        cardContentLayout={cardContentLayoutData}
        layout={layout}
        cardBanner={cardBanner && <CustomCardBanner props={cardBanner} />}
        cardGallery={cardGallery && <CustomCardGallery props={cardGallery} />}
        cardContent={cardContent && <CustomCardContent props={cardContent} />}
      />
    </CardContainer>
  );
};
export default CreateSRPBaseCard;

const CreateSubunit: FunctionComponent = (props: any) => {
  const { cardSubunits, remainingSubunits, dataTracking } = props;
  return (
    <CardContainer
      backgroundColor="white"
      cornerRadius={CardContainerCornerRadius.BOTTOM}
      mobileFullWidth={true}
    >
      {cardSubunits.map((subunit, index) => (
        <SrpCardSubUnit key={index} props={{ ...subunit }} />
      ))}
      {remainingSubunits > 0 && (
        <RemainingSubunitsCard
          remainingSubunits={remainingSubunits}
          dataTracking={dataTracking?.['remainingUnits']}
        />
      )}
    </CardContainer>
  );
};

const CreateSavedAdsCard: FunctionComponent<{}> = (props: any) => (
  <CardContainer backgroundColor="white">
    {/* Created ads to be implemented */}
  </CardContainer>
);

export const createCard = (
  type: any,
  customProps: any,
): FunctionComponent<{}> => {
  const allProps = { type, ...customProps };
  const CardComponent: FunctionComponent<{}> = () => {
    switch (type) {
      case 'savedAds':
        return <CreateSavedAdsCard {...allProps} />;
      case 'baseCard':
        return <CreateSRPBaseCard {...allProps} />;
      case 'subunit':
        return (
          <CreateSubunit
            {...allProps}
            cardSubunits={allProps.cardSubunits}
            remainingSubunits={allProps.remainingSubunits}
          />
        );

      default:
        throw new Error(`Unknown card type: ${type}`);
    }
  };
  return CardComponent;
};
