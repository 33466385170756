import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
} from '@dist-property-frontend/toolkit';
import React, { useState, useContext } from 'react';
import { CardContentPropsType } from './services/srpCard.types';
import { SRPCardsDataTracking } from './helpers/dataTracking';
import { UserDetailsContext } from '../../contexts/userDetailsContext';

const SRPCardContentTopRightSlot = ({
  cardContentProps,
}: {
  cardContentProps: CardContentPropsType;
}) => {
  const [isSaved, setIsSaved] = useState(cardContentProps?.isSaved);
  const userDetails = useContext(UserDetailsContext);

  const handleSaveButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setIsSaved(!isSaved);
    cardContentProps?.onSaveButtonClick({
      event,
      adId: cardContentProps?.id,
      isSaved,
      userId: userDetails.userId,
    });
  };

  return (
    <Button
      data-tracking={SRPCardsDataTracking.cardContent.srp_save_ad}
      buttonSize={ButtonSizes.SMALL}
      buttonType={ButtonTypes.GHOST}
      Icon={<Icon icon={isSaved ? 'HEARTFILLED' : 'HEART'} size={32} />}
      onClick={handleSaveButtonClick}
    />
  );
};

export default SRPCardContentTopRightSlot;
