/**
 * @param props
 * @returns {string[]} Filtered meta properties array.
 */
export const getMetaProps = (props: {
  numBedrooms?: string;
  numBathrooms?: string;
  bathroomType?: string;
  propertyTypeExtraInfo?: string;
  propertySize?: string;
  propertyType?: string;
}): string[] => {
  const metaProps = [
    props.numBedrooms,
    props.numBathrooms ?? props.bathroomType,
    props.propertyTypeExtraInfo,
    props.propertySize,
    props.propertyType,
  ];

  return metaProps.filter((metaProp) => metaProp);
};
